/* --------------- variables --------------------- */
/* Break Point */
section {
  background-color: #F8F8F8;
}

#header {
  background-color: #F8F8F8;
}

.btn span {
  background-color: #fff;
  position: relative;
}

#mv {
  background-color: #F8F8F8;
}
#mv .mvItem {
  position: relative;
}
#mv .mvItem:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(56, 56, 56, 0.3);
  border-radius: 18px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
#aboutus {
  position: relative;
  background-color: transparent;
}
#aboutus:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  background: url(../images/home/aboutus_bg.jpg) no-repeat center/cover;
}
#aboutus h2 {
  color: #fff;
  line-height: 1;
  font-weight: 400;
  font-family: "Unbounded", sans-serif;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
#aboutus .aboutusInner {
  position: relative;
  z-index: 2;
}
#aboutus .aboutTitle ul li {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
#aboutus .aboutTitle ul li h3 {
  color: #fff;
  font-family: "Unbounded", sans-serif;
  font-weight: 400;
  position: absolute;
}
#aboutus .aboutTitle ul li p {
  font-weight: bold;
  color: #fff;
}
#aboutus .aboutImg ul li {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
#aboutus .aboutImg ul li > div {
  background-color: #fff;
  height: 100%;
}
#aboutus .aboutImg ul li p {
  line-height: 1.8;
}
#aboutus .aboutImg ul li p span {
  color: #3AAE36;
}
#aboutus .aboutCatch {
  color: #fff;
  font-weight: bold;
  text-shadow: 1px -1px 2px rgba(0, 0, 0, 0.7), -1px 1px 2px rgba(0, 0, 0, 0.7);
}
#service {
  position: relative;
}
#service h2 {
  position: relative;
  z-index: 3;
  font-weight: 400;
  font-family: "Unbounded", sans-serif;
  color: #fff;
}
#service .serviceInner {
  position: relative;
  z-index: 3;
}
#service .serviceList li {
  background-color: #FAFAFC;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
#service .serviceList li a {
  display: block;
  position: relative;
  height: 100%;
}
#service .serviceList li a.culnetconsortium:before {
  background-color: #69B6E5;
}
#service .serviceList li a.culnetconsortium:after {
  background-color: #69B6E5;
}
#service .serviceList li a.ocattebase:before {
  background-color: #D25E27;
}
#service .serviceList li a.ocattebase:after {
  background-color: #D25E27;
}
#service .serviceList li a.pipeline:before {
  background-color: #2D3C52;
}
#service .serviceList li a.pipeline:after {
  background-color: #2D3C52;
}
#service .serviceList li a.cellag:before {
  background-color: #F1E840;
}
#service .serviceList li a.cellag:after {
  background-color: #F1E840;
}
#service .serviceList li .textWrap p span {
  display: inline-block;
}
#service .serviceList li .textWrap small {
  display: block;
  color: #919191;
}

#ayumi {
  position: relative;
}
#ayumi h2 {
  position: relative;
  font-weight: 400;
  color: #2C3A51;
  z-index: 3;
}
#ayumi .ayumiImg {
  text-align: center;
  position: relative;
  z-index: 3;
}

#news {
  position: relative;
}
#news h2 {
  position: relative;
  z-index: 3;
  font-weight: 400;
  font-family: "Unbounded", sans-serif;
  color: #2C3A51;
}
#news .tabList {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
#news .tabItem {
  width: 25%;
  background-color: #F9F9FB;
  border: 1px solid #F2F2F2;
  color: #2C3A51;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
#news .tabItem.active {
  color: #fff;
  background-color: #2C3A51;
}
#news .tabPanel {
  display: none;
}
#news .tabPanel.active {
  display: block;
}
#news .btnWrap {
  text-align: center;
}

#future {
  position: relative;
  overflow: hidden;
  margin-top: -20px;
}
#future h2 {
  color: #fff;
  line-height: 1;
  font-weight: 400;
  font-family: "Unbounded", sans-serif;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  z-index: 2;
  position: relative;
}
#future .futureInner {
  position: relative;
  z-index: 2;
}
#future .futureCatch h3 {
  font-weight: bold;
  line-height: 1.5;
}
#future .futureCatch .textWrap p {
  line-height: 2.1;
}
#future figure {
  position: relative;
  z-index: 2;
}

#company h2 {
  position: relative;
  z-index: 3;
  font-weight: 400;
  font-family: "Unbounded", sans-serif;
  color: #2C3A51;
}
#company .companyDl > div {
  background-color: #fff;
  border-radius: 5px;
}
#company .companyDl > div dd {
  font-weight: 400;
}
#recruit {
  background: url(../images/home/recruit_bg.jpg) no-repeat center/cover;
}
#recruit .recruitWrap .textWrap h2 {
  font-weight: 400;
  font-family: "Unbounded", sans-serif;
  color: #fff;
  position: relative;
}
#recruit .recruitWrap .textWrap h3 {
  font-weight: bold;
  color: #fff;
  line-height: 1.5;
  text-shadow: 1px -1px 4px rgba(0, 0, 0, 0.2);
}
#recruit .recruitWrap .textWrap p {
  color: #fff;
  text-shadow: 1px -1px 4px rgba(0, 0, 0, 0.2);
}
#recruit .recruitWrap .linkWrap a {
  display: block;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;
}
#recruit .recruitWrap .linkWrap a.comingsoon {
  pointer-events: none;
}
#recruit .recruitWrap .linkWrap a p {
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .btn {
    display: inline-block;
    position: relative;
    padding-right: 30px;
  }
  .btn:after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    background-size: 14px auto;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
  }
  .btn:before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
  .btn span {
    display: flex;
    border-radius: 22px;
    min-width: 172px;
    height: 40px;
    align-items: center;
    justify-content: center;
    color: #2C3A51;
    font-size: 14px;
    margin: 0 auto;
  }
  .btn span:before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 22px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
  #mv {
    padding: 100px 0;
    margin-top: -100px;
  }
  #mv .mvInner {
    padding: 0 20px;
  }
  #mv .mvInner > div {
    position: relative;
  }
  #mv .mvSlide img {
    border-radius: 18px;
    width: 100%;
  }
  #mv .mvCatch {
    position: absolute;
    left: calc(50% - 108px);
    bottom: 30px;
    width: 216px;
    z-index: 3;
  }
  #mv .topNews {
    margin-top: 20px;
    z-index: 4;
  }
  #mv .topNews a {
    display: block;
    background-color: #fff;
    border-radius: 18px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 12px;
    position: relative;
  }
  #mv .topNews a:after {
    content: "";
    display: inline-block;
    width: 47px;
    height: 24px;
    border-radius: 22px;
    background: url(../images/common/arrow_l_w.svg) no-repeat center/cover;
    background-size: 20px auto;
    background-color: #2C3A51;
    border: 1px solid #2C3A51;
    position: absolute;
    bottom: 12px;
    right: 12px;
    transition: 0.5s;
  }
  #mv .topNews a .cat {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #A5C957;
    color: #fff;
    text-align: center;
    line-height: 1;
    font-family: "Unbounded", sans-serif;
    width: 80px;
    height: 20px;
    font-size: 12px;
    font-weight: 400;
    padding-top: 2px;
  }
  #mv .topNews a p {
    margin-top: 15px;
    font-size: 15px;
  }
  #mv .topNews a time {
    margin-top: 20px;
    font-size: 12px;
    display: block;
  }
  #aboutus {
    padding: 60px 20px;
  }
  #aboutus:after {
    content: "";
    display: block;
    background: url(../images/home/lines_sp.svg) no-repeat center;
    background-size: 100% auto;
    width: 336px;
    height: 60px;
    position: absolute;
    top: -60px;
    left: calc(50% - 168px);
  }
  #aboutus h2 {
    font-size: 54px;
    position: absolute;
    top: 0px;
    left: -8px;
    transform: rotate(180deg);
  }
  #aboutus .aboutTitle ul li {
    width: 100%;
    border-radius: 18px;
    min-height: 137px;
    padding: 20px 12px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #aboutus .aboutTitle ul li:before {
    content: "";
    width: 120px;
    height: 5px;
    display: inline-block;
    background-color: #A5C957;
    position: absolute;
    top: 0;
    left: calc(50% - 60px);
  }
  #aboutus .aboutTitle ul li + li {
    margin-top: 15px;
  }
  #aboutus .aboutTitle ul li h3 {
    font-size: 18px;
    padding-left: 23px;
    top: 20px;
    left: 12px;
  }
  #aboutus .aboutTitle ul li h3:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #A5C957;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: calc(50% - 4px);
  }
  #aboutus .aboutTitle ul li p {
    font-size: 21px;
  }
  #aboutus .aboutImg {
    margin-top: 35px;
  }
  #aboutus .aboutImg ul {
    display: flex;
  }
  #aboutus .aboutImg ul li {
    width: 50%;
  }
  #aboutus .aboutImg ul li:first-of-type {
    border-radius: 22px 12px 12px 22px;
    padding: 4px 2px 4px 4px;
  }
  #aboutus .aboutImg ul li:last-of-type {
    border-radius: 12px 22px 22px 12px;
    padding: 4px 4px 4px 2px;
  }
  #aboutus .aboutImg ul li > div {
    border-radius: 15px;
    border: 5px solid #A5C957;
    padding: 20px 15px 20px;
  }
  #aboutus .aboutImg ul li figure {
    text-align: center;
  }
  #aboutus .aboutImg ul li figure img {
    max-width: 80%;
  }
  #aboutus .aboutImg ul li p {
    font-size: 13px;
    text-align: center;
    margin-top: 15px;
  }
  #aboutus .aboutCatch {
    margin-top: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 2.5;
  }
  #aboutus .aboutCatch .strong {
    font-size: 23px;
    margin: 20px 0;
  }
  #aboutus .btnWrap {
    margin-top: 30px;
    text-align: center;
  }
  #service {
    padding: 55px 20px 0;
  }
  #service:before {
    content: "";
    width: 100%;
    height: 93%;
    border-radius: 18px 18px 0 0;
    background-color: #A5C957;
    position: absolute;
    top: 23px;
    left: 0;
    z-index: 2;
  }
  #service:after {
    content: "";
    display: block;
    background: url(../images/home/lines_sp.svg) no-repeat center;
    background-size: 100% auto;
    width: 336px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: calc(50% - 168px);
  }
  #service h2 {
    font-size: 28px;
    padding-left: 30px;
  }
  #service h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: calc(50% - 9px);
  }
  #service .serviceInner {
    padding: 0 20px;
  }
  #service .serviceList {
    margin-top: 30px;
  }
  #service .serviceList li {
    border-radius: 10px 10px 0 0;
  }
  #service .serviceList li + li {
    margin-top: 24px;
  }
  #service .serviceList li a {
    padding-bottom: 30px;
  }
  #service .serviceList li a:before {
    content: "";
    display: inline-block;
    width: 42px;
    height: 42px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: url(../images/common/arrow_sankaku.svg) no-repeat;
    background-size: 8px auto;
    border-radius: 85% 0 0 0;
    background-position: right 11px bottom 11px;
  }
  #service .serviceList li a:after {
    content: "";
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  #service .serviceList li figure {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
  #service .serviceList li .textWrap {
    padding: 20px 20px;
    text-align: center;
  }
  #service .serviceList li .textWrap h3 img {
    max-width: 80%;
  }
  #service .serviceList li .textWrap p {
    border-top: 20px;
  }
  #service .serviceList li .textWrap small {
    margin-top: 15px;
    font-size: 11px;
  }
  #ayumi {
    padding: 90px 20px 0;
  }
  #ayumi:after {
    content: "";
    display: block;
    background: url(../images/home/lines_sp.svg) no-repeat center;
    background-size: 100% auto;
    width: 336px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: calc(50% - 168px);
  }
  #ayumi h2 {
    font-size: 28px;
    padding-left: 30px;
  }
  #ayumi h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: calc(50% - 7px);
  }
  #ayumi .ayumiImg {
    margin-top: 40px;
  }
  #news {
    padding: 70px 20px 75px;
  }
  #news:after {
    content: "";
    display: block;
    background: url(../images/home/lines_sp.svg) no-repeat center;
    background-size: 100% auto;
    width: 336px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: calc(50% - 168px);
  }
  #news .newsInnner {
    position: relative;
    z-index: 3;
  }
  #news h2 {
    font-size: 28px;
    padding-left: 30px;
  }
  #news h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: calc(50% - 9px);
  }
  #news .tabs {
    margin-top: 30px;
  }
  #news .tabList {
    display: flex;
    border-radius: 10px;
    gap: 6px;
    padding: 5px;
  }
  #news .tabItem {
    height: 32px;
    font-size: 11px;
    cursor: pointer;
    border-radius: 8px;
  }
  #news .newsList {
    margin-top: 24px;
  }
  #news .btnWrap {
    margin-top: 45px;
  }
  #future {
    padding: 60px 0px 0;
  }
  #future:before {
    content: "";
    width: 100%;
    height: 90%;
    position: absolute;
    top: 0px;
    left: 0;
    background-color: #C1E6FF;
    border-radius: 18px 18px 0 0;
  }
  #future h2 {
    font-size: 54px;
    position: absolute;
    top: 0px;
    left: -8px;
    transform: rotate(180deg);
  }
  #future .futureInner {
    padding: 0 20px;
  }
  #future .futureCatch h3 {
    font-size: 40px;
  }
  #future .futureCatch .textWrap p {
    margin-top: 30px;
  }
  #future .futureCatch .textWrap .btnWrap {
    margin-top: 40px;
    text-align: center;
  }
  #future figure {
    margin-top: 20px;
    width: calc(100% + 20px);
    transform: translateX(-10px);
  }
  #company {
    padding-top: 80px;
    padding-bottom: 60px;
  }
  #company h2 {
    font-size: 28px;
    padding-left: 50px;
  }
  #company h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 20px;
    top: calc(50% - 9px);
  }
  #company .companyInner {
    padding: 0 20px;
    margin-top: 30px;
  }
  #company .companyDl > div {
    padding: 12px;
  }
  #company .companyDl > div + div {
    margin-top: 15px;
  }
  #company .companyDl > div dd {
    margin-top: 5px;
  }
  #company .btnWrap {
    text-align: center;
    margin-top: 40px;
  }
  #company .btnWrap + p {
    margin-top: 40px;
    font-size: 12px;
  }
  #recruit {
    padding: 30px 20px 60px;
  }
  #recruit .recruitWrap .textWrap h2 {
    font-size: 28px;
    padding-left: 30px;
    padding-bottom: 25px;
    border-bottom: 2px solid #fff;
  }
  #recruit .recruitWrap .textWrap h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0px;
    top: calc(50% - 22px);
  }
  #recruit .recruitWrap .textWrap h3 {
    font-size: 24px;
    margin-top: 23px;
  }
  #recruit .recruitWrap .textWrap p {
    margin-top: 23px;
  }
  #recruit .recruitWrap .linkWrap {
    margin-top: 30px;
  }
  #recruit .recruitWrap .linkWrap a {
    border-radius: 18px;
  }
  #recruit .recruitWrap .linkWrap a:after {
    content: "";
    display: inline-block;
    width: 47px;
    height: 24px;
    border-radius: 22px;
    background: url(../images/common/arrow_l_w.svg) no-repeat center/cover;
    background-size: 20px auto;
    background-color: #2C3A51;
    border: 1px solid #2C3A51;
    position: absolute;
    bottom: 13px;
    right: 12px;
  }
  #recruit .recruitWrap .linkWrap a p {
    font-size: 18px;
    padding: 11px 20px;
  }
}
@media print, screen and (min-width: 801px) {
  .btn {
    display: inline-block;
    position: relative;
    padding-right: 34px;
  }
  .btn:after {
    content: "";
    display: inline-block;
    width: 44px;
    height: 44px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    background-size: 14px auto;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s;
  }
  .btn:before {
    content: "";
    display: inline-block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
  .btn:hover:after {
    border-radius: 0 50% 50% 0;
    background-color: #FED501;
  }
  .btn:hover:before {
    border-radius: 0 50% 50% 0;
  }
  .btn:hover span {
    border-radius: 22px 0 0 22px;
    background-color: #FED501;
  }
  .btn:hover span:before {
    border-radius: 22px 0 0 22px;
  }
  .btn span {
    display: flex;
    border-radius: 22px;
    min-width: 182px;
    height: 44px;
    align-items: center;
    justify-content: center;
    color: #2C3A51;
    font-size: 14px;
    margin: 0 auto;
    transition: 0.5s;
  }
  .btn span:before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 22px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
  }
  #mv {
    padding: 120px 0;
    margin-top: -120px;
  }
  #mv .mvInner {
    margin: 0 auto;
    position: relative;
    padding: 0 40px;
  }
  #mv .mvSlide img {
    border-radius: 18px;
    width: 100%;
  }
  #mv .mvCatch {
    position: absolute;
    left: 68px;
    bottom: 24px;
    padding-right: 45%;
    z-index: 3;
  }
  #mv .topNews {
    position: absolute;
    bottom: 6px;
    right: 40px;
    border-radius: 12px 0 0 0;
    background-color: #F8F8F8;
    padding-top: 19px;
    padding-left: 24px;
    width: 40%;
    z-index: 4;
  }
  #mv .topNews a {
    display: block;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 10px 14px 12px;
    position: relative;
  }
  #mv .topNews a:after {
    content: "";
    display: inline-block;
    width: 47px;
    height: 24px;
    border-radius: 22px;
    background: url(../images/common/arrow_l_w.svg) no-repeat center/cover;
    background-size: 20px auto;
    background-color: #2C3A51;
    border: 1px solid #2C3A51;
    position: absolute;
    bottom: 14px;
    right: 18px;
    transition: 0.5s;
  }
  #mv .topNews a:hover:after {
    background: url(../images/common/arrow_l.svg) no-repeat center/cover;
    background-size: 20px auto;
    background-color: #fff;
  }
  #mv .topNews a .cat {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    background-color: #A5C957;
    color: #fff;
    text-align: center;
    line-height: 1;
    font-family: "Unbounded", sans-serif;
    width: 102px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    padding-top: 2px;
  }
  #mv .topNews a p {
    padding: 0 14px;
    margin-top: 20px;
    font-size: 15px;
  }
  #mv .topNews a time {
    padding-left: 14px;
    margin-top: 30px;
    font-size: 16px;
    display: block;
  }
  #aboutus {
    padding: 90px 0 70px;
  }
  #aboutus:after {
    content: "";
    display: block;
    background: url(../images/home/lines_pc.svg) no-repeat center;
    width: 901px;
    height: 50px;
    position: absolute;
    top: -50px;
    left: calc(50% - 450px);
  }
  #aboutus h2 {
    position: absolute;
    white-space: nowrap;
    font-size: 128px;
    top: 90px;
    left: -20px;
    transform: rotate(180deg);
  }
  #aboutus .aboutusInner {
    max-width: 940px;
    width: 90%;
    margin: 0 auto;
  }
  #aboutus .aboutTitle ul {
    display: flex;
    gap: 20px;
  }
  #aboutus .aboutTitle ul li {
    width: calc(50% - 10px);
    border-radius: 16px;
    min-height: 165px;
    padding: 50px 35px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #aboutus .aboutTitle ul li:before {
    content: "";
    width: 120px;
    height: 5px;
    display: inline-block;
    background-color: #A5C957;
    position: absolute;
    top: 0;
    left: calc(50% - 60px);
  }
  #aboutus .aboutTitle ul li h3 {
    font-size: 21px;
    padding-left: 23px;
    top: 25px;
    left: 20px;
  }
  #aboutus .aboutTitle ul li h3:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #A5C957;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: calc(50% - 4px);
  }
  #aboutus .aboutTitle ul li p {
    font-size: 25px;
  }
  #aboutus .aboutImg {
    margin-top: 60px;
  }
  #aboutus .aboutImg ul {
    display: flex;
  }
  #aboutus .aboutImg ul li {
    width: 50%;
  }
  #aboutus .aboutImg ul li:first-of-type {
    border-radius: 27px 17px 17px 27px;
    padding: 10px 5px 10px 10px;
  }
  #aboutus .aboutImg ul li:last-of-type {
    border-radius: 17px 27px 27px 17px;
    padding: 10px 10px 10px 5px;
  }
  #aboutus .aboutImg ul li > div {
    border-radius: 20px;
    border: 5px solid #A5C957;
    padding: 35px 20px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #aboutus .aboutImg ul li p {
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
  }
  #aboutus .aboutCatch {
    margin-top: 45px;
    text-align: center;
    font-size: 16px;
    line-height: 2.5;
  }
  #aboutus .aboutCatch .strong {
    font-size: 23px;
    margin: 20px 0;
  }
  #aboutus .btnWrap {
    margin-top: 40px;
    text-align: center;
  }
  #service {
    padding-top: 165px;
  }
  #service:before {
    content: "";
    width: 100%;
    height: 392px;
    border-radius: 15px 15px 0 0;
    background-color: #A5C957;
    position: absolute;
    top: 116px;
    left: 0;
    z-index: 2;
  }
  #service:after {
    content: "";
    display: block;
    background: url(../images/home/lines_pc.svg) no-repeat center;
    width: 901px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: calc(50% - 450px);
  }
  #service h2 {
    max-width: 1188px;
    width: 90%;
    margin: 0 auto;
    font-size: 35px;
    padding-left: 40px;
  }
  #service h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
  }
  #service .serviceInner {
    max-width: 1188px;
    width: 90%;
    margin: 0 auto;
  }
  #service .serviceList {
    margin-top: 35px;
    display: flex;
    gap: 24px;
  }
  #service .serviceList li {
    width: calc(25% - 12px);
    border-radius: 10px 10px 0 0;
  }
  #service .serviceList li:nth-of-type(1) {
    transition-delay: 0s;
  }
  #service .serviceList li:nth-of-type(2) {
    transition-delay: 0.2s;
  }
  #service .serviceList li:nth-of-type(3) {
    transition-delay: 0.4s;
  }
  #service .serviceList li:nth-of-type(4) {
    transition-delay: 0.6s;
  }
  #service .serviceList li a {
    padding-bottom: 30px;
  }
  #service .serviceList li a:before {
    content: "";
    display: inline-block;
    width: 54px;
    height: 55px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: url(../images/common/arrow_sankaku.svg) no-repeat;
    background-size: 10px auto;
    border-radius: 85% 0 0 0;
    background-position: right 15px bottom 14px;
    transition: 0.5s;
  }
  #service .serviceList li a:after {
    content: "";
    display: block;
    width: 100%;
    height: 7px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  #service .serviceList li a:hover:before {
    width: 62px;
    height: 63px;
  }
  #service .serviceList li a:hover figure img {
    transform: scale(1.2);
  }
  #service .serviceList li figure {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
  #service .serviceList li figure img {
    transition: 0.5s;
  }
  #service .serviceList li .textWrap {
    padding: 20px 20px;
    text-align: center;
  }
  #service .serviceList li .textWrap h3 img {
    max-width: 80%;
  }
  #service .serviceList li .textWrap p {
    margin-top: 15px;
  }
  #service .serviceList li .textWrap small {
    margin-top: 15px;
    font-size: 11px;
  }
  #ayumi {
    padding-top: 120px;
  }
  #ayumi:after {
    content: "";
    display: block;
    background: url(../images/home/lines_pc.svg) no-repeat center;
    width: 901px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: calc(50% - 450px);
  }
  #ayumi h2 {
    max-width: 1188px;
    width: 90%;
    margin: 0 auto;
    font-size: 30px;
    padding-left: 40px;
  }
  #ayumi h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: calc(50% - 9px);
  }
  #ayumi .ayumiImg img {
    max-width: 90%;
  }
  #news {
    padding-top: 70px;
    padding-bottom: 116px;
  }
  #news:after {
    content: "";
    display: block;
    background: url(../images/home/lines_pc.svg) no-repeat center;
    width: 901px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: calc(50% - 450px);
  }
  #news .newsInnner {
    max-width: 994px;
    margin: 0 auto;
    width: 90%;
    position: relative;
    z-index: 3;
  }
  #news h2 {
    max-width: 1188px;
    width: 90%;
    margin: 0 auto;
    font-size: 35px;
    padding-left: 40px;
  }
  #news h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
  }
  #news .tabs {
    margin-top: 50px;
  }
  #news .tabList {
    display: flex;
    border-radius: 30px;
    gap: 20px;
    padding: 5px 6px;
    max-width: 874px;
    margin: 0 auto;
  }
  #news .tabItem {
    height: 48px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 24px;
  }
  #news .tabItem:hover {
    color: #fff;
    background-color: #2C3A51;
  }
  #news .newsList {
    margin-top: 48px;
  }
  #news .btnWrap {
    margin-top: 70px;
  }
  #future {
    padding: 50px 0 0px;
  }
  #future:before {
    content: "";
    width: 100%;
    height: 80%;
    position: absolute;
    top: 0px;
    left: 0;
    background-color: #C1E6FF;
    border-radius: 20px 20px 0 0;
  }
  #future h2 {
    position: absolute;
    white-space: nowrap;
    font-size: 101px;
    top: 60px;
    left: -12px;
    transform: rotate(180deg);
  }
  #future .futureInner {
    max-width: 940px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 50px;
  }
  #future .futureCatch {
    display: flex;
    gap: 45px;
  }
  #future .futureCatch h3 {
    font-size: 54px;
    flex: none;
  }
  #future .futureCatch .textWrap .btnWrap {
    text-align: right;
    margin-top: 30px;
  }
  #future figure {
    width: calc(100% + 60px);
    transform: translateX(-30px);
  }
  #company {
    padding-top: 90px;
    padding-bottom: 80px;
  }
  #company h2 {
    max-width: 1188px;
    width: 90%;
    margin: 0 auto;
    font-size: 35px;
    padding-left: 40px;
  }
  #company h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
  }
  #company .companyInner {
    width: 90%;
    max-width: 1000px;
    margin: 60px auto 0;
  }
  #company .companyDl > div {
    display: flex;
    padding: 13px 33px;
  }
  #company .companyDl > div + div {
    margin-top: 25px;
  }
  #company .companyDl > div dt {
    width: 140px;
    padding-right: 30px;
    flex: none;
  }
  #company .btnWrap {
    text-align: center;
    margin-top: 50px;
  }
  #company .btnWrap + p {
    margin-top: 50px;
    font-size: 14px;
  }
  #recruit {
    padding: 60px 0;
  }
  #recruit .recruitInner {
    max-width: 1050px;
    margin: 0 auto;
    width: 90%;
  }
  #recruit .recruitWrap {
    display: flex;
  }
  #recruit .recruitWrap .textWrap {
    width: 48%;
    padding-right: 70px;
  }
  #recruit .recruitWrap .textWrap h2 {
    font-size: 35px;
    padding-left: 40px;
    border-bottom: 2px solid #fff;
    padding-bottom: 20px;
  }
  #recruit .recruitWrap .textWrap h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: calc(50% - 21px);
  }
  #recruit .recruitWrap .textWrap h3 {
    font-size: 29px;
    margin-top: 15px;
  }
  #recruit .recruitWrap .textWrap p {
    margin-top: 25px;
  }
  #recruit .recruitWrap .linkWrap {
    width: 52%;
  }
  #recruit .recruitWrap .linkWrap a {
    border-radius: 18px;
  }
  #recruit .recruitWrap .linkWrap a:after {
    content: "";
    display: inline-block;
    width: 47px;
    height: 24px;
    border-radius: 22px;
    background: url(../images/common/arrow_l_w.svg) no-repeat center/cover;
    background-size: 20px auto;
    background-color: #2C3A51;
    border: 1px solid #2C3A51;
    position: absolute;
    bottom: 9px;
    right: 12px;
    transition: 0.5s;
  }
  #recruit .recruitWrap .linkWrap a:hover:after {
    background: url(../images/common/arrow_l.svg) no-repeat center/cover;
    background-size: 20px auto;
    background-color: #fff;
  }
  #recruit .recruitWrap .linkWrap a p {
    font-size: 18px;
    padding: 8px 20px;
  }
}
@media screen and (max-width: 1200px) {
  body {
    padding-top: 76px;
  }
  #header.active #logo {
    height: 76px;
    background-color: #F8F8F8;
  }
}
@media print, screen and (min-width: 1201px) {
  body {
    padding-top: 90px;
  }
  #header.active #logo {
    height: 90px;
    background-color: #F8F8F8;
  }
}