body{
    @if $type==sp_1200 {
        padding-top: 76px;
    }
    @if $type==pc_1200 {
        padding-top: 90px;
    }
}
section{
    @if $type=="" {
        background-color: #F8F8F8;
    }
}
#header{
    @if $type=="" {
        background-color: #F8F8F8;
    }
    @if $type==sp_1200 {
        &.active{
            #logo{
                height: 76px;
                background-color: #F8F8F8;
            }
        }
    }
    @if $type==pc_1200 {
        &.active{
            #logo{
                height: 90px;
                background-color: #F8F8F8;
            }
        }
    }
}
.btn{
    @if $type==sp {
        display: inline-block;
        position: relative;
        padding-right: 30px;
        &:after{
            content: "";
            display: inline-block;
            width: 40px;
            height: 40px;
            background: url(../images/common/arrow.svg) no-repeat center / cover;
            background-size: 14px auto;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 0;
        }
        &:before{
            content: "";
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 0;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }
    }
    @if $type==pc {
        display: inline-block;
        position: relative;
        padding-right: 34px;
        &:after{
            content: "";
            display: inline-block;
            width: 44px;
            height: 44px;
            background: url(../images/common/arrow.svg) no-repeat center / cover;
            background-size: 14px auto;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 0;
            transition: .5s;
        }
        &:before{
            content: "";
            display: inline-block;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 0;
            transition: .5s;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }
        &:hover{
            &:after{
                border-radius: 0 50% 50% 0;
                background-color: $c_yellow;
            }
            &:before{
                border-radius: 0 50% 50% 0;
            }
            span{
                border-radius: 22px 0 0 22px;
                background-color: $c_yellow;
                &:before{
                    border-radius: 22px 0 0 22px;
                }
            }
        }
    }
    span{
        @if $type=="" {
            background-color: #fff;
            position: relative;
        }
        @if $type==sp {
            display: flex;
            border-radius: 22px;
            min-width: 172px;
            height: 40px;
            align-items: center;
            justify-content: center;
            color: #2C3A51;
            font-size: 14px;
            margin: 0 auto;
            &:before{
                content: "";
                width: 100%;
                height: 100%;
                border-radius: 22px;
                position: absolute;
                top: 0;
                left: 0;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            }
        }
        @if $type==pc {
            display: flex;
            border-radius: 22px;
            min-width: 182px;
            height: 44px;
            align-items: center;
            justify-content: center;
            color: #2C3A51;
            font-size: 14px;
            margin: 0 auto;
            transition: .5s;
            &:before{
                content: "";
                width: 100%;
                height: 100%;
                border-radius: 22px;
                position: absolute;
                top: 0;
                left: 0;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                transition: .5s;
            }
        }
    }
}

#mv{
    @if $type=="" {
        background-color: #F8F8F8;
        .mvItem{
            position: relative;
            &:before{
                content: "";
                width: 100%;
                height: 100%;
                background-color: rgba(56,56,56,.3);
                border-radius: 18px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
            }
        }
    }
    @if $type==sp {
        padding: 100px 0;
        margin-top: -100px;
    }
    @if $type==pc {
        padding: 120px 0;
        margin-top: -120px;
    }
    .mvInner{
        @if $type=="" {
        }
        @if $type==sp {
            padding: 0 20px;
            > div{
                position: relative;
            }
        }
        @if $type==pc {
            margin: 0 auto;
            position: relative;
            padding: 0 40px;
        }
    }
    .mvSlide{
        @if $type==sp {
            img{
                border-radius: 18px;
                width: 100%;
            }
        }
        @if $type==pc {
            img{
                border-radius: 18px;
                width: 100%;
            }
        }
    }
    .mvCatch{
        @if $type==sp {
            position: absolute;
            left: calc(50% - 108px);
            bottom: 30px;
            width: 216px;
            z-index: 3;
        }
        @if $type==pc {
            position: absolute;
            left: 68px;
            bottom: 24px;
            padding-right: 45%;
            z-index: 3;
        }
    }
    .topNews{
        @if $type==sp {
            margin-top: 20px;
            z-index: 4;
            a{
                display: block;
                background-color: #fff;
                border-radius: 18px;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,.1);
                padding: 12px;
                position: relative;
                &:after{
                    content: "";
                    display: inline-block;
                    width: 47px;
                    height: 24px;
                    border-radius: 22px;
                    background: url(../images/common/arrow_l_w.svg) no-repeat center / cover;
                    background-size: 20px auto;
                    background-color: #2C3A51;
                    border: 1px solid #2C3A51;
                    position: absolute;
                    bottom: 12px;
                    right: 12px;
                    transition: .5s;
                }
                .cat{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    background-color: $c_base;
                    color: #fff;
                    text-align: center;
                    line-height: 1;
                    @include fontEn;
                    width: 80px;
                    height: 20px;
                    font-size: 12px;
                    font-weight: 400;
                    padding-top: 2px;
                }
                p{
                    margin-top: 15px;
                    font-size: 15px;
                }
                time{
                    margin-top: 20px;
                    font-size: 12px;
                    display: block;
                }
            }
        }
        @if $type==pc {
            position: absolute;
            bottom: 6px;
            right: 40px;
            border-radius: 12px 0 0 0;
            background-color: #F8F8F8;
            padding-top: 19px;
            padding-left: 24px;
            width: 40%;
            z-index: 4;
            a{
                display: block;
                background-color: #fff;
                border-radius: 12px;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,.1);
                padding: 10px 10px 14px 12px;
                position: relative;
                &:after{
                    content: "";
                    display: inline-block;
                    width: 47px;
                    height: 24px;
                    border-radius: 22px;
                    background: url(../images/common/arrow_l_w.svg) no-repeat center / cover;
                    background-size: 20px auto;
                    background-color: #2C3A51;
                    border: 1px solid #2C3A51;
                    position: absolute;
                    bottom: 14px;
                    right: 18px;
                    transition: .5s;
                }
                &:hover:after{
                    background: url(../images/common/arrow_l.svg) no-repeat center / cover;
                    background-size: 20px auto;
                    background-color: #fff;
                }
                .cat{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 7px;
                    background-color: $c_base;
                    color: #fff;
                    text-align: center;
                    line-height: 1;
                    @include fontEn;
                    width: 102px;
                    height: 30px;
                    font-size: 14px;
                    font-weight: 400;
                    padding-top: 2px;
                }
                p{
                    padding: 0 14px;
                    margin-top: 20px;
                    font-size: 15px;
                }
                time{
                    padding-left: 14px;
                    margin-top: 30px;
                    font-size: 16px;
                    display: block;
                }
            }
        }
    }
}//mv

#aboutus{
    @if $type=="" {
        position: relative;
        background-color: transparent;
        &:before{
            content:"";
            display:block;
            position:fixed;
            top:0;
            left:0;
            z-index:-1;
            width:100%;
            height:100vh;
            background: url(../images/home/aboutus_bg.jpg) no-repeat center / cover;
        }
    }
    @if $type==sp {
        padding: 60px 20px;
        &:after{
            content: "";
            display: block;
            background: url(../images/home/lines_sp.svg) no-repeat center;
            background-size: 100% auto;
            width: 336px;
            height: 60px;
            position: absolute;
            top: -60px;
            left: calc(50% - 168px);
        }
    }
    @if $type==pc {
        padding: 90px 0 70px;
        &:after{
            content: "";
            display: block;
            background: url(../images/home/lines_pc.svg) no-repeat center;
            width: 901px;
            height: 50px;
            position: absolute;
            top: -50px;
            left: calc(50% - 450px);
        }
    }
    h2{
        @if $type=="" {
            color: #fff;
            line-height: 1;
            font-weight: 400;
            @include fontEn;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
        }
        @if $type==sp {
            font-size: 54px;
            position: absolute;
            top: 0px;
            left: -8px;
            transform: rotate(180deg);
        }
        @if $type==pc {
            position: absolute;
            white-space: nowrap;
            font-size: 128px;
            top: 90px;
            left: -20px;
            transform: rotate(180deg);
        }
    }
    .aboutusInner{
        @if $type=="" {
            position: relative;
            z-index: 2;
        }
        @if $type==sp {
        }
        @if $type==pc {
            max-width: 940px;
            width: 90%;
            margin: 0 auto;
        }
    }
    .aboutTitle{
        ul{
            @if $type=="" {
            }
            @if $type==sp {
            }
            @if $type==pc {
                display: flex;
                gap: 20px;
            }
            li{
                @if $type=="" {
                    position: relative;
                    background-color: rgba(255, 255, 255, 0.2);
                    backdrop-filter: blur(10px);
                    -webkit-backdrop-filter: blur(10px);
                }
                @if $type==sp {
                    width: 100%;
                    border-radius: 18px;
                    min-height: 137px;
                    padding: 20px 12px 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:before{
                        content: "";
                        width: 120px;
                        height: 5px;
                        display: inline-block;
                        background-color: $c_base;
                        position: absolute;
                        top: 0;
                        left: calc(50% - 60px);
                    }
                    + li{
                        margin-top: 15px;
                    }
                }
                @if $type==pc {
                    width: calc(50% - 10px);
                    border-radius: 16px;
                    min-height: 165px;
                    padding: 50px 35px 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:before{
                        content: "";
                        width: 120px;
                        height: 5px;
                        display: inline-block;
                        background-color: $c_base;
                        position: absolute;
                        top: 0;
                        left: calc(50% - 60px);
                    }
                }
                h3{
                    @if $type=="" {
                        color: #fff;
                        @include fontEn;
                        font-weight: 400;
                        position: absolute;
                    }
                    @if $type==sp {
                        font-size: 18px;
                        padding-left: 23px;
                        top: 20px;
                        left: 12px;
                        &:before{
                            content: "";
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            background-color: $c_base;
                            border-radius: 50%;
                            position: absolute;
                            left: 0;
                            top: calc(50% - 4px);
                        }
                    }
                    @if $type==pc {
                        font-size: 21px;
                        padding-left: 23px;
                        top: 25px;
                        left: 20px;
                        &:before{
                            content: "";
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            background-color: $c_base;
                            border-radius: 50%;
                            position: absolute;
                            left: 0;
                            top: calc(50% - 4px);
                        }
                    }
                }
                p{
                    @if $type=="" {
                        font-weight: bold;
                        color: #fff;
                    }
                    @if $type==sp {
                        font-size: 21px;
                    }
                    @if $type==pc {
                        font-size: 25px;
                    }
                }
            }
        }
    }
    .aboutImg{
        @if $type=="" {
        }
        @if $type==sp {
            margin-top: 35px;
        }
        @if $type==pc {
            margin-top: 60px;
        }
        ul{
            @if $type=="" {
            }
            @if $type==sp {
                display: flex;
            }
            @if $type==pc {
                display: flex;
            }
            li{
                @if $type=="" {
                    background-color: rgba(255, 255, 255, 0.2);
                    backdrop-filter: blur(10px);
                    -webkit-backdrop-filter: blur(10px);
                }
                @if $type==sp {
                    width: 50%;
                    &:first-of-type{
                        border-radius: 22px 12px 12px 22px;
                        padding: 4px 2px 4px 4px;
                    }
                    &:last-of-type{
                        border-radius: 12px 22px 22px 12px;
                        padding: 4px 4px 4px 2px;
                    }
                }
                @if $type==pc {
                    width: 50%;
                    &:first-of-type{
                        border-radius: 27px 17px 17px 27px;
                        padding: 10px 5px 10px 10px;
                    }
                    &:last-of-type{
                        border-radius: 17px 27px 27px 17px;
                        padding: 10px 10px 10px 5px;
                    }
                }
                > div{
                    @if $type=="" {
                        background-color: #fff;
                        height: 100%;
                    }
                    @if $type==sp {
                        border-radius: 15px;
                        border: 5px solid $c_base;
                        padding: 20px 15px 20px;
                    }
                    @if $type==pc {
                        border-radius: 20px;
                        border: 5px solid $c_base;
                        padding: 35px 20px 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                }
                figure{
                    @if $type=="" {
                    }
                    @if $type==sp {
                        text-align: center;
                        img{
                            max-width: 80%;
                        }
                    }
                    @if $type==pc {
                    }
                }
                p{
                    @if $type=="" {
                        line-height: 1.8;
                        span{
                            color: #3AAE36;
                        }
                    }
                    @if $type==sp {
                        font-size: 13px;
                        text-align: center;
                        margin-top: 15px;
                    }
                    @if $type==pc {
                        font-size: 16px;
                        text-align: center;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    .aboutCatch{
        @if $type=="" {
            color: #fff;
            font-weight: bold;
            text-shadow: 1px -1px 2px rgba(0, 0, 0, 0.7),-1px 1px 2px rgba(0, 0, 0, 0.7);
        }
        @if $type==sp {
            margin-top: 30px;
            text-align: center;
            font-size: 16px;
            line-height: 2.5;
            .strong{
                font-size: 23px;
                margin: 20px 0;
            }
        }
        @if $type==pc {
            margin-top: 45px;
            text-align: center;
            font-size: 16px;
            line-height: 2.5;
            .strong{
                font-size: 23px;
                margin: 20px 0;
            }
        }
    }
    .btnWrap{
        @if $type==sp {
            margin-top: 30px;
            text-align: center;
        }
        @if $type==pc {
            margin-top: 40px;
            text-align: center;
        }
    }
}//aboutus

#service{
    @if $type=="" {
        position: relative;
    }
    @if $type==sp {
        padding: 55px 20px 0;
        &:before{
            content: "";
            width: 100%;
            height: 93%;
            border-radius: 18px 18px 0 0;
            background-color: $c_base;
            position: absolute;
            top: 23px;
            left: 0;
            z-index: 2;
        }
        &:after{
            content: "";
            display: block;
            background: url(../images/home/lines_sp.svg) no-repeat center;
            background-size: 100% auto;
            width: 336px;
            height: 100%;
            position: absolute;
            top: 0px;
            left: calc(50% - 168px);
        }
    }
    @if $type==pc {
        padding-top: 165px;
        &:before{
            content: "";
            width: 100%;
            height: 392px;
            border-radius: 15px 15px 0 0;
            background-color: $c_base;
            position: absolute;
            top: 116px;
            left: 0;
            z-index: 2;
        }
        &:after{
            content: "";
            display: block;
            background: url(../images/home/lines_pc.svg) no-repeat center;
            width: 901px;
            height: 100%;
            position: absolute;
            top: 0px;
            left: calc(50% - 450px);
        }
    }
    h2{
        @if $type=="" {
            position: relative;
            z-index: 3;
            font-weight: 400;
            @include fontEn;
            color: #fff;
        }
        @if $type==sp {
            font-size: 28px;
            padding-left: 30px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                width: 18px;
                height: 18px;
                position: absolute;
                left: 0;
                top: calc(50% - 9px);
            }
        }
        @if $type==pc {
            max-width: 1188px;
            width: 90%;
            margin: 0 auto;
            font-size: 35px;
            padding-left: 40px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                width: 23px;
                height: 23px;
                position: absolute;
                left: 0;
                top: calc(50% - 12px);
            }
        }
    }
    .serviceInner{
        @if $type=="" {
            position: relative;
            z-index: 3;
        }
        @if $type==sp {
            padding: 0 20px;
        }
        @if $type==pc {
            max-width: 1188px;
            width: 90%;
            margin: 0 auto;
        }
    }
    .serviceList{
        @if $type=="" {
        }
        @if $type==sp {
            margin-top: 30px;
        }
        @if $type==pc {
            margin-top: 35px;
            display: flex;
            gap: 24px;
        }
        li{
            @if $type=="" {
                background-color: #FAFAFC;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            }
            @if $type==sp {
                border-radius: 10px 10px 0 0;
                + li{
                    margin-top: 24px;
                }
            }
            @if $type==pc {
                width: calc(25% - 12px);
                border-radius: 10px 10px 0 0;
                &:nth-of-type(1){
                    transition-delay: 0s;
                }
                &:nth-of-type(2){
                    transition-delay: 0.2s;
                }
                &:nth-of-type(3){
                    transition-delay: 0.4s;
                }
                &:nth-of-type(4){
                    transition-delay: 0.6s;
                }
            }
            a{
                @if $type=="" {
                    display: block;
                    position: relative;
                    height: 100%;
                    &.culnetconsortium{
                        &:before{
                            background-color: #69B6E5;
                        }
                        &:after{
                            background-color: #69B6E5;
                        }
                    }
                    &.ocattebase{
                        &:before{
                            background-color: #D25E27;
                        }
                        &:after{
                            background-color: #D25E27;
                        }
                    }
                    &.pipeline{
                        &:before{
                            background-color: #2D3C52;
                        }
                        &:after{
                            background-color: #2D3C52;
                        }
                    }
                    &.cellag{
                        &:before{
                            background-color: #F1E840;
                        }
                        &:after{
                            background-color: #F1E840;
                        }
                    }
                }
                @if $type==sp {
                    padding-bottom: 30px;
                    &:before{
                        content: "";
                        display: inline-block;
                        width: 42px;
                        height: 42px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background: url(../images/common/arrow_sankaku.svg) no-repeat;
                        background-size: 8px auto;
                        border-radius: 85% 0 0 0;
                        background-position: right 11px bottom 11px;
                    }
                    &:after{
                        content: "";
                        display: block;
                        width: 100%;
                        height: 5px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
                @if $type==pc {
                    padding-bottom: 30px;
                    &:before{
                        content: "";
                        display: inline-block;
                        width: 54px;
                        height: 55px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background: url(../images/common/arrow_sankaku.svg) no-repeat;
                        background-size: 10px auto;
                        border-radius: 85% 0 0 0;
                        background-position: right 15px bottom 14px;
                        transition: .5s;
                    }
                    &:after{
                        content: "";
                        display: block;
                        width: 100%;
                        height: 7px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                    &:hover{
                        &:before{
                            width: 62px;
                            height: 63px;
                        }
                        figure img{
                            transform: scale(1.2);
                        }
                    }
                } 
            }
            figure{
                @if $type=="" {
                }
                @if $type==sp {
                    border-radius: 10px 10px 0 0;
                    overflow: hidden;
                }
                @if $type==pc {
                    border-radius: 10px 10px 0 0;
                    overflow: hidden;
                    img{
                        transition: .5s;
                    }
                }
            }
            .textWrap{
                @if $type=="" {
                }
                @if $type==sp {
                    padding: 20px 20px;
                    text-align: center;
                }
                @if $type==pc {
                    padding: 20px 20px;
                    text-align: center;
                }
                h3{
                    @if $type=="" {
                    }
                    @if $type==sp {
                        img{
                            max-width: 80%;
                        }
                    }
                    @if $type==pc {
                        img{
                            max-width: 80%;
                        }
                    }
                }
                p{
                    @if $type=="" {
                        span{
                            display: inline-block;
                        }
                    }
                    @if $type==sp {
                        border-top: 20px;
                    }
                    @if $type==pc {
                        margin-top: 15px;
                    }
                }
                small{
                    @if $type=="" {
                        display: block;
                        color: #919191;
                    }
                    @if $type==sp {
                        margin-top: 15px;
                        font-size: 11px;
                    }
                    @if $type==pc {
                        margin-top: 15px;
                        font-size: 11px;
                    }
                }
            }
        }
    }
}//service

#ayumi{
    @if $type=="" {
        position: relative;
    }
    @if $type==sp {
        padding: 90px 20px 0;
        &:after{
            content: "";
            display: block;
            background: url(../images/home/lines_sp.svg) no-repeat center;
            background-size: 100% auto;
            width: 336px;
            height: 100%;
            position: absolute;
            top: 0px;
            left: calc(50% - 168px);
        }
    }
    @if $type==pc {
        padding-top: 120px;
        &:after{
            content: "";
            display: block;
            background: url(../images/home/lines_pc.svg) no-repeat center;
            width: 901px;
            height: 100%;
            position: absolute;
            top: 0px;
            left: calc(50% - 450px);
        }
    }
    h2{
        @if $type=="" {
            position: relative;
            font-weight: 400;
            color: #2C3A51;
            z-index: 3;
        }
        @if $type==sp {
            font-size: 28px;
            padding-left: 30px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                width: 18px;
                height: 18px;
                position: absolute;
                left: 0;
                top: calc(50% - 7px);
            }
        }
        @if $type==pc {
            max-width: 1188px;
            width: 90%;
            margin: 0 auto;
            font-size: 30px;
            padding-left: 40px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                width: 23px;
                height: 23px;
                position: absolute;
                left: 0;
                top: calc(50% - 9px);
            }
        }
    }
    .ayumiImg{
        @if $type=="" {
            text-align: center;
            position: relative;
            z-index: 3;
        }
        @if $type==sp {
            margin-top: 40px;
        }
        @if $type==pc {
            img{
                max-width: 90%;
            }
        }
    }
}//ayumi

#news{
    @if $type=="" {
        position: relative;
    }
    @if $type==sp {
        padding: 70px 20px 75px;
        &:after{
            content: "";
            display: block;
            background: url(../images/home/lines_sp.svg) no-repeat center;
            background-size: 100% auto;
            width: 336px;
            height: 100%;
            position: absolute;
            top: 0px;
            left: calc(50% - 168px);
        }
        .newsInnner{
            position: relative;
            z-index: 3;
        }
    }
    @if $type==pc {
        padding-top: 70px;
        padding-bottom: 116px;
        &:after{
            content: "";
            display: block;
            background: url(../images/home/lines_pc.svg) no-repeat center;
            width: 901px;
            height: 100%;
            position: absolute;
            top: 0px;
            left: calc(50% - 450px);
        }
        .newsInnner{
            max-width: 994px;
            margin: 0 auto;
            width: 90%;
            position: relative;
            z-index: 3;
        }
    }
    h2{
        @if $type=="" {
            position: relative;
            z-index: 3;
            font-weight: 400;
            @include fontEn;
            color: #2C3A51;
        }
        @if $type==sp {
            font-size: 28px;
            padding-left: 30px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                width: 18px;
                height: 18px;
                position: absolute;
                left: 0;
                top: calc(50% - 9px);
            }
        }
        @if $type==pc {
            max-width: 1188px;
            width: 90%;
            margin: 0 auto;
            font-size: 35px;
            padding-left: 40px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                width: 23px;
                height: 23px;
                position: absolute;
                left: 0;
                top: calc(50% - 12px);
            }
        }
    }
    .tabs{
        @if $type=="" {
        }
        @if $type==sp {
            margin-top: 30px;
        }
        @if $type==pc {
            margin-top: 50px;
        } 
    }
    .tabList{
        @if $type=="" {
            background-color: #fff;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }
        @if $type==sp {
            display: flex;
            border-radius: 10px;
            gap: 6px;
            padding: 5px;
        }
        @if $type==pc {
            display: flex;
            border-radius: 30px;
            gap: 20px;
            padding: 5px 6px;
            max-width: 874px;
            margin: 0 auto;
        } 
    }
    .tabItem{
        @if $type=="" {
            width: calc(100%/4);
            background-color: #F9F9FB;
            border: 1px solid #F2F2F2;
            color: #2C3A51;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s;
            &.active{
                color: #fff;
                background-color: #2C3A51;
            }
        }
        @if $type==sp {
            height: 32px;
            font-size: 11px;
            cursor: pointer;
            border-radius: 8px;
        }
        @if $type==pc {
            height: 48px;
            font-size: 14px;
            cursor: pointer;
            border-radius: 24px;
            &:hover{
                color: #fff;
                background-color: #2C3A51;
            }
        }
    }
    .tabCont{
        @if $type=="" {
        }
        @if $type==sp {
        }
        @if $type==pc {
        }
    }
    .tabPanel{
        @if $type=="" {
            display: none;
            &.active{
                display: block;
            }
        }
        @if $type==sp {
        }
        @if $type==pc {
        }
    }
    .newsList{
        @if $type=="" {
        }
        @if $type==sp {
            margin-top: 24px;
        }
        @if $type==pc {
            margin-top: 48px;
        }
    }
    .btnWrap{
        @if $type=="" {
            text-align: center;
        }
        @if $type==sp {
            margin-top: 45px;
        }
        @if $type==pc {
            margin-top: 70px;
        }
    }
}//news

#future{
    @if $type=="" {
        position: relative;
        overflow: hidden;
        margin-top: -20px;
    }
    @if $type==sp {
        padding: 60px 0px 0;
        &:before{
            content: "";
            width: 100%;
            height: 90%;
            position: absolute;
            top: 0px;
            left: 0;
            background-color: #C1E6FF;
            border-radius: 18px 18px 0 0;
        }
    }
    @if $type==pc {
        padding: 50px 0 0px;
        &:before{
            content: "";
            width: 100%;
            height: 80%;
            position: absolute;
            top: 0px;
            left: 0;
            background-color: #C1E6FF;
            border-radius: 20px 20px 0 0;
        }
    }
    h2{
        @if $type=="" {
            color: #fff;
            line-height: 1;
            font-weight: 400;
            @include fontEn;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            z-index: 2;
            position: relative;
        }
        @if $type==sp {
            font-size: 54px;
            position: absolute;
            top: 0px;
            left: -8px;
            transform: rotate(180deg);
        }
        @if $type==pc {
            position: absolute;
            white-space: nowrap;
            font-size: 101px;
            top: 60px;
            left: -12px;
            transform: rotate(180deg);
        }
    }
    .futureInner{
        @if $type=="" {
            position: relative;
            z-index: 2;
        }
        @if $type==sp {
            padding: 0 20px;
        }
        @if $type==pc {
            max-width: 940px;
            width: 90%;
            margin: 0 auto;
			padding-bottom: 50px;
        }
    }
    .futureCatch{
        @if $type=="" {
        }
        @if $type==sp {
        }
        @if $type==pc {
            display: flex;
            gap: 45px;
        }
        h3{
            @if $type=="" {
                font-weight: bold;
                line-height: 1.5;
            }
            @if $type==sp {
                font-size: 40px;
            }
            @if $type==pc {
                font-size: 54px;
                flex:none;
            }
        }
        .textWrap{
            @if $type=="" {
            }
            @if $type==sp {
            }
            @if $type==pc {
            }
            p{
                @if $type=="" {
                    line-height: 2.1;
                }
                @if $type==sp {
                    margin-top: 30px;
                }
                @if $type==pc {
                }
            }
            .btnWrap{
                @if $type=="" {
                }
                @if $type==sp {
                    margin-top: 40px;
                    text-align: center;
                }
                @if $type==pc {
                    text-align: right;
                    margin-top: 30px;
                }
            }
        }
    }
    figure{
        @if $type=="" {
            position: relative;
            z-index: 2;
        }
        @if $type==sp {
            margin-top: 20px;
            width: calc(100% + 20px);
            transform: translateX(-10px);
        }
        @if $type==pc {
            width: calc(100% + 60px);
            transform: translateX(-30px);
        }
    }
}//future

#company{
    @if $type=="" {
    }
    @if $type==sp {
        padding-top: 80px;
        padding-bottom: 60px;
    }
    @if $type==pc {
        padding-top: 90px;
        padding-bottom: 80px;
    }
    h2{
        @if $type=="" {
            position: relative;
            z-index: 3;
            font-weight: 400;
            @include fontEn;
            color: #2C3A51;
        }
        @if $type==sp {
            font-size: 28px;
            padding-left: 50px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                width: 18px;
                height: 18px;
                position: absolute;
                left: 20px;
                top: calc(50% - 9px);
            }
        }
        @if $type==pc {
            max-width: 1188px;
            width: 90%;
            margin: 0 auto;
            font-size: 35px;
            padding-left: 40px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                width: 23px;
                height: 23px;
                position: absolute;
                left: 0;
                top: calc(50% - 12px);
            }
        }
    }
    .companyInner{
        @if $type=="" {
        }
        @if $type==sp {
            padding: 0 20px;
            margin-top: 30px;
        }
        @if $type==pc {
            width: 90%;
            max-width: 1000px;
            margin: 60px auto 0;
        }
    }
    .companyDl{
        @if $type=="" {
        }
        @if $type==sp {
        }
        @if $type==pc {
        }
        > div{
            @if $type=="" {
                background-color: #fff;
                border-radius: 5px;
            }
            @if $type==sp {
                padding: 12px;
                + div{
                    margin-top: 15px;
                }
            }
            @if $type==pc {
                display: flex;
                padding: 13px 33px;
                + div{
                    margin-top: 25px;
                }
            }
            dt{
                @if $type=="" {
                }
                @if $type==sp {
                }
                @if $type==pc {
                    width: 140px;
                    padding-right: 30px;
                    flex: none;
                }
            }
            dd{
                @if $type=="" {
                    font-weight: 400;
                }
                @if $type==sp {
                    margin-top: 5px;
                }
                @if $type==pc {
                }
            }
        }
    }
    .btnWrap{
        @if $type==sp {
            text-align: center;
            margin-top: 40px;
            + p{
                margin-top: 40px;
                font-size: 12px;
            }
        }
        @if $type==pc {
            text-align: center;
            margin-top: 50px;
            + p{
                margin-top: 50px;
                font-size: 14px;
            }
        }
    }
}//company

#recruit{
    @if $type=="" {
        background: url(../images/home/recruit_bg.jpg) no-repeat center / cover;
    }
    @if $type==sp {
        padding: 30px 20px 60px;
    }
    @if $type==pc {
        padding: 60px 0;
    }
    .recruitInner{
        @if $type=="" {
        }
        @if $type==sp {
        }
        @if $type==pc {
            max-width: 1050px;
            margin: 0 auto;
            width: 90%;
        }
    }
    .recruitWrap{
        @if $type=="" {
        }
        @if $type==sp {
        }
        @if $type==pc {
            display: flex;
        }
        .textWrap{
            @if $type=="" {
            }
            @if $type==sp {
            }
            @if $type==pc {
                width: 48%;
                padding-right: 70px;
            }
            h2{
                @if $type=="" {
                    font-weight: 400;
                    @include fontEn;
                    color: #fff;
                    position: relative;
                }
                @if $type==sp {
                    font-size: 28px;
                    padding-left: 30px;
                    padding-bottom: 25px;
                    border-bottom: 2px solid #fff;
                    &:before{
                        content: "";
                        display: inline-block;
                        background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        left: 0px;
                        top: calc(50% - 22px);
                    }
                }
                @if $type==pc {
                    font-size: 35px;
                    padding-left: 40px;
                    border-bottom: 2px solid #fff;
                    padding-bottom: 20px;
                    &:before{
                        content: "";
                        display: inline-block;
                        background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                        width: 23px;
                        height: 23px;
                        position: absolute;
                        left: 0;
                        top: calc(50% - 21px);
                    }
                }
            }
            h3{
                @if $type=="" {
                    font-weight: bold;
                    color: #fff;
                    line-height: 1.5;
                    text-shadow: 1px -1px 4px rgba(0, 0, 0, 0.2);
                }
                @if $type==sp {
                    font-size: 24px;
                    margin-top: 23px;
                }
                @if $type==pc {
                    font-size: 29px;
                    margin-top: 15px;
                }
            }
            p{
                @if $type=="" {
                    color: #fff;
                    text-shadow: 1px -1px 4px rgba(0, 0, 0, 0.2);
                }
                @if $type==sp {
                    margin-top: 23px;
                }
                @if $type==pc {
                    margin-top: 25px;
                }
            }
        }
        .linkWrap{
            @if $type=="" {
            }
            @if $type==sp {
                margin-top: 30px;
            }
            @if $type==pc {
                width: 52%;
            }
            a{
                @if $type=="" {
                    display: block;
                    background-color: #fff;
                    position: relative;
                    overflow: hidden;
                    border: 2px solid #fff;
                    &.comingsoon{
                        pointer-events: none;
                    }
                }
                @if $type==sp {
                    border-radius: 18px;
                    &:after{
                        content: "";
                        display: inline-block;
                        width: 47px;
                        height: 24px;
                        border-radius: 22px;
                        background: url(../images/common/arrow_l_w.svg) no-repeat center / cover;
                        background-size: 20px auto;
                        background-color: #2C3A51;
                        border: 1px solid #2C3A51;
                        position: absolute;
                        bottom: 13px;
                        right: 12px;
                    }
                }
                @if $type==pc {
                    border-radius: 18px;
                    &:after{
                        content: "";
                        display: inline-block;
                        width: 47px;
                        height: 24px;
                        border-radius: 22px;
                        background: url(../images/common/arrow_l_w.svg) no-repeat center / cover;
                        background-size: 20px auto;
                        background-color: #2C3A51;
                        border: 1px solid #2C3A51;
                        position: absolute;
                        bottom: 9px;
                        right: 12px;
                        transition: .5s;
                    }
                    &:hover:after{
                        background: url(../images/common/arrow_l.svg) no-repeat center / cover;
                        background-size: 20px auto;
                        background-color: #fff;
                    }
                }
                p{
                    @if $type=="" {
                        font-weight: bold;
                    }
                    @if $type==sp {
                        font-size: 18px;
                        padding: 11px 20px;
                    }
                    @if $type==pc {
                        font-size: 18px;
                        padding: 8px 20px;
                    }
                }
            }
        }
    }
}//recruit

.sample{
    @if $type=="" {
    }
    @if $type==sp {
    }
    @if $type==pc {
    }
}