/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp:800px;
$breakpoint_tb:800px;
$breakpoint_pc:$breakpoint_sp+1;
$sp_1200:1200px;
$pc_1200:$sp_1200+1;


$c_base : #A5C957;
$c_yellow : #FED501;
$c_text : #2C3A51;
$c_href : #2663B5;
$c_border: #E8E8E8;


@mixin maxPC {
    max-width: 994px;
    width: 90%;
    margin: 0 auto;
}


@mixin fontJa {
    font-family: "Noto Sans JP", sans-serif;
}

@mixin fontEn {
    font-family: "Unbounded", sans-serif;
}

// @font-face {
//     font-family: "Noto Sans JP";
//     src: url("../fonts/NotoSansJP.woff2") format("woff2");
//     unicode-range: U+3000-9FFF, U+FF00-FFEF; // 日本語と全角文字
// }

// @font-face {
//     font-family: "Unbounded";
//     src: url("../fonts/Unbounded.woff2") format("woff2");
//     unicode-range: U+0000-007F, U+0080-00FF; // 英数字と一部の特殊文字
// }